import React, { useEffect, useState, useMemo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { withCookies } from 'react-cookie';

import InternationalRestrictionsList from '@components/Restrictions/InternationalRestrictionsList';
import { MainMenu } from '@components/MainMenu';
import ContentMenu from '@components/ContentMenu';
import MobileContentMenu from '@components/MobileContentMenu';
import { SearchBar } from '@components/SearchBar';
import SortBar from '@components/SortBar';
import { Container } from '@components/Container';
import { Item } from '@components/SortBar/types';
import { MobileContainer } from '@components/MobileContainer';
import { menuTabIndexByPathMap } from 'constants/menuTabIndexByPathMap';
import { INTERNATIONAL_PAGE_URI } from 'constants/pageUris';
import { getSearchData } from 'helpers/getSearchData';

import { InternationalRestrictions, DomesticRestrictions } from 'types/contentful';

import { NoCardMatchesFoundMessage } from '@components/NoCardMatchesFoundMessage';
import { GOOGLE_TRANSLATE_COOKIE_NAME } from '@components/LanguageTranslator/constants';
import { SearchItem } from '@components/SearchBar/types';
import Loader from '@components/Loader/Loader';
import { DataExtendedProps, LanguageKey, Tabs } from './types';
import { IPageProps } from '../types';
import {
  contentMenuItems,
  sortByItem,
  translationNamesCountries,
  translationNamesStates,
  nonRomanLang,
  translationPlaceholder,
  homeSortByItem,
} from './constants';

const International = (
  props: IPageProps<(InternationalRestrictions | DomesticRestrictions)[]>,
): React.ReactElement => {
  const {
    uri,
    navigate,
    pageContext,
    location: { pathname, origin },
    cookies,
  } = props;

  const isMainPage = pathname === '/';

  const sortOptions = isMainPage ? homeSortByItem : sortByItem;

  const [selectedTab, setSelectedTab] = useState(0);

  const [searchData, setSearchData] = useState<DataExtendedProps[]>([]);

  const [isSearchUsed, setIsSearchUsed] = useState(false);

  const [isTouched, setIsTouched] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [sortBySelectedOption, setSortBySelectedOption] = useState(sortOptions[isMainPage ? 2 : 0]);

  const { regionMenu } = menuTabIndexByPathMap;

  useEffect(() => {
    const getUri = pathname.replace(/\/$/, '');

    const selectedTabValue =
      menuTabIndexByPathMap.regionMenu[uri === '/' ? INTERNATIONAL_PAGE_URI : getUri];

    setSelectedTab(selectedTabValue);
  }, [pathname, uri]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isLoading, searchData]);

  const langCode = (cookies?.get(GOOGLE_TRANSLATE_COOKIE_NAME) || '').substr(6, 2);

  const isNonRomanLang = nonRomanLang.test(langCode);

  const placeholderValue =
    translationPlaceholder[langCode as LanguageKey] || translationPlaceholder.en;

  const findTranslationName = useCallback(
    (item: InternationalRestrictions | DomesticRestrictions) => {
      const name = (item as InternationalRestrictions).countryCode
        ? translationNamesCountries[(item as InternationalRestrictions).countryCode]?.[langCode]
        : translationNamesStates[(item as DomesticRestrictions).stateCode]?.[langCode];

      return (isNonRomanLang && name) || '';
    },
    [isNonRomanLang, langCode],
  );

  const allDataExtended =
    pageContext.allData?.map((item: InternationalRestrictions | DomesticRestrictions) => ({
      ...item,
      translationName: findTranslationName(item),
    })) || [];

  const handleSearchData = async (
    toValue: SearchItem | null,
    fieldNames: string[],
    isCountryCodeExist?: boolean,
  ) => {
    setIsLoading(true);

    if (allDataExtended) {
      const searchParams = {
        allData: allDataExtended,
        toValue: toValue?.value || '',
        fieldNames,
        isTouched,
        isCountryCodeExist,
      };

      setSortBySelectedOption(sortOptions[isMainPage ? 2 : 0]);

      const searchData = getSearchData<DataExtendedProps>(searchParams);

      setSearchData(searchData);

      setIsSearchUsed(!!toValue);
    }

    if (toValue) {
      setSelectedTab(Tabs.TopDestinations);

      return;
    }

    navigate('/');
  };

  const handleSortSelect = (data: DataExtendedProps[], selectedOption: Item): void => {
    setSortBySelectedOption(selectedOption);
    setIsSearchUsed(true);
    setSearchData(data);
  };

  const data = useMemo(() => {
    if (isSearchUsed) {
      return searchData;
    }

    return pageContext.data?.map((item: InternationalRestrictions | DomesticRestrictions) => ({
      ...item,
      translationName: findTranslationName(item),
    }));
  }, [findTranslationName, isSearchUsed, pageContext.data, searchData]);

  useEffect(() => {
    if (!isSearchUsed && searchData?.length) {
      setSearchData([]);
    }
  }, [isSearchUsed, searchData]);

  const isSelectedTabHidden = useMemo(() => data.length <= 2, [data]);

  const mobileContentMenuItems = useMemo(() => {
    if (isSelectedTabHidden) {
      return [
        {
          label: 'Search results',
          uri: '/',
        },
        ...contentMenuItems,
      ];
    }

    return contentMenuItems;
  }, [isSelectedTabHidden]);

  return (
    <>
      <MainMenu uri={uri} navigate={navigate} staticUri={pathname}>
        <MobileContainer>
          <SearchBar
            data={allDataExtended}
            handleSearchData={handleSearchData}
            placeholder={placeholderValue}
            isNonRomanLang={isNonRomanLang}
            setIsTouched={() => setIsTouched(true)}
            isSearchUsed={isSearchUsed}
          />
        </MobileContainer>
      </MainMenu>

      <MobileContentMenu
        {...props}
        items={mobileContentMenuItems}
        selectedTab={selectedTab}
        changeTab={setSelectedTab}
      />

      <ContentMenu
        {...props}
        tabIndexes={regionMenu}
        selectedTab={selectedTab}
        items={contentMenuItems}
        isDesktopOnly
        isSelectedTabHidden={isSelectedTabHidden}
        changeTab={setSelectedTab}
      />

      <Container>
        <SortBar
          {...props}
          data={data}
          showItemsCounter
          showMaxLogo
          options={sortOptions}
          searchData={searchData}
          handleSortSelect={handleSortSelect}
          selectedOption={sortBySelectedOption}
        />
      </Container>

      {isLoading ? (
        <Loader />
      ) : isEmpty(data) && isSearchUsed ? (
        <NoCardMatchesFoundMessage />
      ) : (
        <InternationalRestrictionsList data={data} url={origin} />
      )}
    </>
  );
};

export default withCookies(International);
