import React, { useRef } from 'react';

import { classNames } from 'utils/classNames';
import ReactTooltip from 'react-tooltip';
import { getTranstlatedText } from 'helpers/getTranslatedText';
import { getStatusText, getStatusTooltip } from './helpers';
import { LabelType } from './types';
import styles from './StatusLabel.module.css';

type Props = {
  status: string;
  type: LabelType;
  labelStyles?: string;
  statusStyles?: string;
};

export function StatusLabel(props: Props): React.ReactElement | any {
  const { status, type } = props;

  const ref = useRef<HTMLDivElement>(null);

  // TODO: change after the requirments are clear
  if (status === 'None') {
    return null;
  }

  const labelContainerStyles = classNames([styles.labelContainer, props.labelStyles || '']);
  const statusStyles = classNames([
    styles.status,
    styles[`statusColor${props.status}`],
    props.statusStyles || '',
  ]);

  const currentText = getStatusText(status);

  return (
    <div
      className={labelContainerStyles}
      ref={ref}
      data-tip={JSON.stringify(getStatusTooltip(status, type))}
      onMouseEnter={() => ReactTooltip.show(ref.current as HTMLDivElement)}
      onMouseLeave={() => ReactTooltip.hide()}
    >
      <span className={statusStyles}>{getTranstlatedText(currentText, '/auto/fr')}</span>
    </div>
  );
}
