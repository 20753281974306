/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { createRef, useEffect, useState } from 'react';
import Select, { ActionMeta, ValueType } from 'react-select';

import { noTranslateClassName } from '@components/LanguageTranslator/constants';
import { useQueryParam, StringParam } from 'use-query-params';
import { useScreenMatchOnMobile } from '@hooks/useScreenMatchOnMobile';
import {
  DomesticDataExtendedProps,
  InternationalDataExtendedProps,
} from '@templates/international/types';
import { createDropDownItems } from './helpers';

import styles from './SearchBar.module.css';
import { ClearIndicator } from './components/ClearIndicator';
import {
  createSelectStyles,
  FOCUS_BORDER_COLOR,
  BLUR_BORDER_COLOR,
  GRAY_BORDER_COLOR,
} from './SearchBarSelectStyles';

import { SearchItem, Props } from './types';

const SearchBar = (props: Props): React.ReactElement => {
  const { placeholder, isNonRomanLang, handleSearchData, setIsTouched, data, isSearchUsed } = props;

  const toSelectRef = createRef<any>();

  const [items] = useState(createDropDownItems(data));

  const [toFieldData, setToFieldData] = useState<SearchItem | null>(null);

  const isMobile = useScreenMatchOnMobile();

  const [toFieldBorderColor, setToFieldBorderColor] = useState<string>(
    isMobile ? GRAY_BORDER_COLOR : BLUR_BORDER_COLOR,
  );

  const [toCountryCode, setToCountryCode] = useQueryParam('toCountryCode', StringParam);

  const [stateCode, setStateCode] = useQueryParam('stateCode', StringParam);

  const searchClassName = isNonRomanLang ? noTranslateClassName : '';

  const isCountryCodeExist = !!toCountryCode;
  useEffect(() => {
    if (toCountryCode) {
      const toValue = toCountryCode || '';

      const getMutatedObject = (value: string): SearchItem => {
        const item = data.filter(
          ({ countryCode }) => countryCode?.toLowerCase() === value?.toLowerCase(),
        );

        const obj = item[0];

        const { countryName, countryCode } = obj;

        const { stateCode } = obj as DomesticDataExtendedProps;

        const { countryCode3Char } = obj as InternationalDataExtendedProps;

        return {
          value: countryCode,
          label: countryName,
          countryCode3Char,
          stateCode,
        };
      };

      setToCountryCode(undefined);

      const toObj = getMutatedObject(toValue);

      setToCountryCode(undefined);

      handleSearchData(toObj, ['countryCode'], isCountryCodeExist);
    }
  }, [data, handleSearchData, isCountryCodeExist, setToCountryCode, toCountryCode]);

  useEffect(() => {
    if (stateCode) {
      const stateValue = { value: stateCode || '' };

      setStateCode(undefined);

      handleSearchData(stateValue, ['stateCode']);
    }
  }, [handleSearchData, setStateCode, stateCode]);

  useEffect(() => {
    if (!isSearchUsed) {
      setToFieldData(null);
    }
  }, [isSearchUsed]);

  const onSearch = (toFieldValue: SearchItem | null): void => {
    setToCountryCode(undefined);
    setStateCode(undefined);

    handleSearchData(toFieldValue, ['countryName', 'translationName'], isCountryCodeExist);
  };

  const handleToFieldInputChange = (value: SearchItem, action: ActionMeta<SearchItem>): void => {
    if (!toFieldData && !value) {
      return;
    }

    setIsTouched();

    if (action.action === 'select-option') {
      setToFieldData(value);
      setToFieldBorderColor(BLUR_BORDER_COLOR);
    }

    if (action.action === 'clear') {
      setToFieldData(null);

      setToFieldBorderColor(FOCUS_BORDER_COLOR);
    }

    onSearch(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.control}>
        <span className={styles.text}>I am traveling to</span>

        <div className={styles.selectContainer}>
          <Select
            inputId="toSelect"
            styles={createSelectStyles(toFieldBorderColor)}
            isClearable
            value={toFieldData}
            onChange={(item: ValueType<SearchItem>, action: ActionMeta<SearchItem>) =>
              handleToFieldInputChange(item as SearchItem, action)
            }
            options={items}
            placeholder={placeholder}
            className={searchClassName}
            noOptionsMessage={() => null}
            components={{ ClearIndicator }}
            ref={toSelectRef}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
