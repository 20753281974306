import React, { SyntheticEvent } from 'react';

import { MaxSecurityLogo } from '@components/MaxSecurityLogo';

import styles from './MobileContentMenu.module.css';

type Item = { label: string; uri: string };

type Props = {
  navigate: (uri: string) => void;
  items: Item[];
  uri: string;
  selectedTab: number;
  changeTab: (index: number) => void;
};

function MobileContainerMenu(props: Props): React.ReactElement {
  const { navigate, selectedTab, items, changeTab } = props;

  const handleChange = (event: SyntheticEvent): void => {
    const uri = (event.target as HTMLSelectElement).value;

    const index = items.findIndex(item => item.uri === uri);

    changeTab(index);

    if (uri !== props.uri) {
      navigate(uri);
    } else if (index !== selectedTab) {
      navigate('/');
      setTimeout(() => window.location.replace(uri), 0);
    }
  };

  return (
    <div className={styles.mobileContentMenuContainer}>
      <div className={styles.selectWrapper}>
        <div className={styles.mobileContentMenuHeader}>Show Regions</div>

        <select
          value={items[selectedTab]?.uri}
          className={styles.mobileSelect}
          onChange={handleChange}
        >
          {items.map((item: Item) => (
            <option key={item.uri} value={item.uri}>
              {item.label}
            </option>
          ))}
        </select>
      </div>

      <MaxSecurityLogo className={styles.mobileMaxSecurityWrapper} />
    </div>
  );
}

export default MobileContainerMenu;
