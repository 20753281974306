const commonSelectStyles = {
  container: base => ({
    ...base,
    margin: '8px 0 0',
    '@media (max-width: 720px)': {
      font: '400 14px/19px "Open Sans"',
      margin: '8px 0 0',
    },
  }),
  menu: base => ({
    ...base,
    position: 'absolute',
    zIndex: 1,
    width: 360,
    borderRadius: 8,
    backgroundColor: '#fff',
    filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.22))',
    '@media (max-width: 720px)': {
      width: '100%',
    },
  }),
  placeholder: base => ({
    ...base,
    font: '400 15px/19px "Open Sans"',
    color: 'rgba(105, 104, 110, 0.8)',
    '@media (max-width: 720px)': {
      font: '400 13px/14px "Open Sans"',
    },
    // '@media (max-width: 279px)': {
    //   textOverflow: 'ellipsis',
    //   whiteSpace: 'nowrap',
    //   overflow: 'hidden',
    // },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: base => ({
    ...base,
    '& > svg': {
      display: 'none',
    },
  }),
  singleValue: base => ({
    ...base,
    padding: 0,
    font: '300 16px/19px "Open Sans"',
    color: '#404040',
    '@media (max-width: 720px)': {
      font: '300 14px/19px "Open Sans"',
    },
  }),
  valueContainer: base => ({
    ...base,
    padding: '0 19px 0 0',
  }),
  input: base => ({
    ...base,
    '& input': {
      font: '300 16px/19px "Open Sans"',
      color: '#404040',
      '@media (max-width: 720px)': {
        font: '300 14px/19px "Open Sans"',
      },
    },
  }),
  indicatorsContainer: base => ({
    ...base,
    cursor: 'pointer',
    '& div': {
      cursor: 'pointer',
    },
    '& div:last-child': {
      display: 'none',
    },
    '& img': {
      width: 40,
      height: 40,
    },
  }),
  menuList: base => ({
    ...base,
    maxHeight: 220,
    borderRadius: 8,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#B9C7D4' : 'white',
    font: '300 16px/19px "Open Sans"',
    color: !state.isFocused ? '#5A88C6' : '#404040',
    '@media (max-width: 720px)': {
      font: '300 14px/19px "Open Sans"',
    },
  }),
};

const createControlStyles = controlBorderColor => ({
  height: 48,
  padding: '0 0 0 19px',
  borderColor: `${controlBorderColor}`,
  boxShadow: 'none',
  font: '400 16px/19px "Open Sans"',
  color: '#404040',
  cursor: 'text',
  '@media (max-width: 720px)': {
    paddingLeft: 7,
    paddingRight: 0,
    font: '400 14px/19px "Open Sans"',
  },
});

export const SELECT_BORDER_COLOR = '#F38316';
export const FOCUS_BORDER_COLOR = '#5A88C6';
export const BLUR_BORDER_COLOR = '#25426A';
export const GRAY_BORDER_COLOR = '#aaaeb3';

export const createSelectStyles = controlBorderColor => ({
  ...commonSelectStyles,
  control: base => ({
    ...base,
    ...createControlStyles(controlBorderColor),
    borderWidth: controlBorderColor === SELECT_BORDER_COLOR ? 2 : 1,
    borderRadius: 8,
  }),
});
