import { InternationalRestrictions, DomesticRestrictions } from 'types/contentful';

export type DataExtendedProps = InternationalDataExtendedProps | DomesticDataExtendedProps;

export type InternationalDataExtendedProps = InternationalRestrictions & {
  translationName: string;
};

export type DomesticDataExtendedProps = DomesticRestrictions & {
  translationName: string;
  countryCode?: string;
};

export type LanguageKey = 'ja' | 'ru' | 'zh';

export enum IdType {
  country = 2,
  state = 3,
}

export enum Tabs {
  TopDestinations,
  USStates,
  Americas,
  Europe,
  Africa,
  MENA,
  Asia,
}
