import {
  DomesticDataExtendedProps,
  InternationalDataExtendedProps,
} from '@templates/international/types';
import { SearchItem } from './types';

type Data = InternationalDataExtendedProps | DomesticDataExtendedProps;

export const createDropDownItems = (data: Data[]): SearchItem[] => {
  return data.map(props => {
    const { translationName, countryName } = props;
    const { countryCode3Char } = props as InternationalDataExtendedProps;
    const { stateCode } = props as DomesticDataExtendedProps;

    const name = translationName || countryName;

    return {
      label: name,
      value: name,
      countryCode3Char,
      stateCode,
    };
  });
};
