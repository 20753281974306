type SearchParams<T> = {
  allData: T[];
  toValue: string;
  fromValue?: string;
  fieldNames?: string[];
  isCountryCodeExist?: boolean;
  isTouched?: boolean;
};

const findMatched = <T>({
  allData,
  fieldNames,
  toValue,
  isCountryCodeExist,
  isTouched,
}: SearchParams<T>): T[] =>
  allData.filter(
    item =>
      fieldNames?.filter((key: string) => {
        const base = item[key]?.toLowerCase();

        return (
          base === toValue?.toLowerCase() ||
          (isCountryCodeExist && !isTouched && base === toValue?.toLowerCase())
        );
      }).length,
  );

export const getSearchData = <T>(params: SearchParams<T>): T[] => {
  const { allData, toValue, fromValue, fieldNames, isCountryCodeExist, isTouched } = params;
  const toFilterResult = findMatched({
    allData,
    fieldNames,
    toValue,
    fromValue,
    isCountryCodeExist,
    isTouched,
  });

  return toFilterResult;
};
