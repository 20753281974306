import React from 'react';

import { MaxSecurityIcon } from '@components/CustomizedIcons/MaxSecurityIcon';

import { classNames } from 'utils/classNames';

import styles from './MaxSecurityLogo.module.css';

type MaxSecurityProps = {
  className?: string;
};

const MaxSecurityLogo = ({ className = '' }: MaxSecurityProps): React.ReactElement => (
  <a
    className={classNames([className, styles.maxSecurityIconWrapper])}
    href="https://www.max-security.com/travel-security/"
    target="_blank"
    rel="noreferrer"
  >
    <MaxSecurityIcon className={styles.iconHover} />

    <div className={styles.maxSecurityLabel}>
      Powered by
      <br />
      <span className="notranslate"> Max Security</span>
    </div>
  </a>
);

export default MaxSecurityLogo;
