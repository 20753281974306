import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import { classNames } from 'utils/classNames';

import { createJsonForTooltip } from '../helpers';

import Arrow from '../Icons/Arrow';

import styles from '../ExpandContentRegion.module.css';

interface IForecastProps {
  currentTrend: {
    className: string;
    text: string;
    tooltipText: string;
  };
}

const ForecastView = ({ currentTrend }: IForecastProps): React.ReactElement | null => {
  const ref = useRef<HTMLDivElement>(null);

  if (!currentTrend) {
    return null;
  }

  return (
    <div
      className={classNames([styles.forecast, styles[currentTrend.className]])}
      ref={ref}
      data-tip={createJsonForTooltip(currentTrend)}
      onMouseEnter={() => ReactTooltip.show(ref.current as HTMLDivElement)}
      onMouseLeave={() => ReactTooltip.hide()}
    >
      <span>
        <Arrow className={`${currentTrend.className}Arrow`} />
        Forecast: {currentTrend.text}
      </span>
    </div>
  );
};

export default ForecastView;
