import React from 'react';
import Select, { ValueType } from 'react-select';
import cloneDeep from 'lodash/cloneDeep';

import MaxSecurity from '@components/MaxSecurityLogo/MaxSecurityLogo';

import { classNames } from 'utils/classNames';

import { sortByFieldNameAsc, sortByFieldNameDesc } from '@components/SortBar/heplers';

import { DataExtendedProps } from '@templates/international/types';
import { SortBarSelectStyles } from './SortBarSelectStyles';
import styles from './SortBar.module.css';

type Item = {
  label: string;
  fieldName: string;
};

type Props<T> = {
  showItemsCounter?: boolean;
  showMaxLogo?: boolean;
  data: T[];
  options: Item[];
  handleSortSelect: (data: T[], Item: Item) => void;
  selectedOption: Item;
  searchData: DataExtendedProps[];
};

function SortBar<T>(props: Props<T>): React.ReactElement {
  const { options, data, showItemsCounter, selectedOption, showMaxLogo, searchData } = props;

  const handleSortSelect = (optionToSelect: ValueType<Item>): void => {
    const castedOptionToSelect = optionToSelect as Item;
    const newData = cloneDeep(data);
    const sortPredicate =
      castedOptionToSelect.fieldName === 'updatedAt'
        ? sortByFieldNameDesc(castedOptionToSelect.fieldName)
        : sortByFieldNameAsc(castedOptionToSelect.fieldName);

    props.handleSortSelect(newData.sort(sortPredicate), castedOptionToSelect);
  };

  return (
    <div className={styles.sortBarWrapper}>
      <div className={styles.sortBarContainer}>
        {showItemsCounter && (
          <span className={classNames([styles.sortBarIndicator, styles.cardCounter])}>
            Found:&nbsp;
            <span className={styles.count} translate="no">
              {data.length}
            </span>
          </span>
        )}

        {(!searchData?.length || searchData?.length > 1) && (
          <div className={styles.sortBlock}>
            <span className={styles.sortBarIndicator}>Sort by:</span>
            <Select<Item>
              styles={SortBarSelectStyles}
              value={selectedOption}
              onChange={handleSortSelect}
              options={options}
              isSearchable={false}
            />
          </div>
        )}
      </div>

      {showMaxLogo && <MaxSecurity className={styles.maxSecurityWrapper} />}
    </div>
  );
}

export default SortBar;
